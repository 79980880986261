import Vue from 'vue'
import store from '@/store/index' //para acceder a state 

import { handlerErrors, showAlertMessage, consoleMsgFinally, toJson, makeTime } from '@/helpers/helpers'
import { processItemsCart, createPayloadToSave, setGuestsInPayload, makeTotalAndDiscountPayload, makeParamsDiscountManager } from '@/helpers/shopHelper'
import { fetchDestinations, fetchDepartaments, fetchAirlines, fetchTypeClients, fetchHotels, fetchLanguages, fetchTypeService, fetchItineraryRules } from '@/modules/start/store/actions'
import { uploadFileFromOrder } from '@/modules/mySales/store/actions'
import { saveOwnerBooking } from '@/modules/fivesClub/store/catalogs/actions'
import { createPayloadOwnerBooking } from '@/helpers/fivesClubHelper'


export async function getContentForItineraries( context ){
    const content = await Promise.all([
        fetchDestinations(), fetchAirlines(), fetchTypeService(), fetchItineraryRules(), //make 4 requests
    ])

    const [ destinations, airlines, TypeService,ItineraryRules ] = content

    context.commit('start/setDestinations', destinations, { root: true }) //muto state destinations en start
    context.commit('start/setAirlines', airlines, { root: true }) //muto state airlines en start
    context.commit('start/setTypeService', TypeService, { root: true }) //muto state TypeService en start
    context.commit('start/setItineraryRules', ItineraryRules, { root: true }) //muto state ItineraryRules en start
    return content
}

export async function getContentForBookings( context ) {
    const content = await Promise.all([
        fetchDepartaments(), fetchInclucions(), //make 2 requests
    ])
    const [ departaments, inclucions ] = content

    context.commit('start/setDepartaments', departaments, { root: true }) //muto state departaments en start
    context.commit('shop/setInclucions', inclucions, { root: true }) //muto state inclucions en start

    return content
}

export async function getInitialContentCheckout( context ){
    const payloadHotels = { onlyDingusCode: false, all: false, showInWeb: true }
    const content = await Promise.all([
        fetchTypeClients(), fetchHotels(payloadHotels), fetchLanguages(), //make 3 requests
    ])
    const [ customerTypes, hotels, languages ] = content

    context.commit('start/setCustomerTypes', customerTypes, { root: true }) //muto state customerTypes en start
    context.commit('start/setHotels', hotels, { root: true }) //muto state hotels en start
    context.commit('start/setLanguages', languages, { root: true }) //muto state languages en start
    return content
}

export async function checkIfClientExists({ commit }, cliente) {
    const client = toJson(cliente)

    try {
        commit('shop/setLoadingProcess', true, { root: true })
        const response = await Vue.axios({
            url: `/customers/?email=${client.email}`,
        })

        const clientData = response && response.data
        return clientData.id ? clientData : false

    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('shop/setErrorCheckEmail', errorMsg, { root: true })
    } finally {
        commit('shop/setLoadingProcess', false, { root: true })
        consoleMsgFinally('shop/checkIfClientExists', 'La petición para verificar si el cliente existe se ha terminado')
    }
}
export async function fetchDataRoom({ commit }, datos) {

    try {
        commit('shop/setLoadingProcess', true, { root: true })
        const response = await Vue.axios({
            method: 'POST',
            url: `/sihot/getguest/`,
            data: datos
        })
        const respuesta = response && response.data

        const { status, message } = respuesta
        if (status) {
            const data = toJson(respuesta.data)
            //orden alfabetico por guestname
            data.sort((a, b) => a.guestname > b.guestname ? 1 : -1)
            commit('shop/setDataRoom', data, { root: true })
            // commit('shop/unsetLastNameSearch', '', { root: true })
            // commit('shop/unsetErrorRoomCharge', '', { root: true })
        } else {
            commit('shop/setDataRoom', [], { root: true })
            showAlertMessage('Respuesta búsqueda', 'BellIcon', `😪 ${message}.`, 'danger', 3000, 'bottom-right')
        }
    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('shop/setErrorRoomCharge', errorMsg, { root: true })
    } finally {
        commit('shop/setLoadingProcess', false, { root: true })
        consoleMsgFinally('shop/fetchDataRoom', 'La petición para obtener los datos del guest ha terminado')
    }
}

export async function fetchDataOrder({ commit }, order) {

    try {
        commit('shop/setLoadingProcess', true, { root: true })
        const response = await Vue.axios({
            url: `/orders/?order=${order}`,
        })
       
        const respuesta = response && response.data
        const { message } = respuesta
        const data = toJson(respuesta)
        if (data) {
            commit('shop/setDataOrder', data, { root: true })
        } else {
            commit('shop/setDataOrder', [], { root: true })
            showAlertMessage('Respuesta búsqueda', 'BellIcon', `😪 ${message}.`, 'danger', 4000, 'bottom-right')
        }
    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('shop/setErrorRoomCharge', errorMsg, { root: true })
    } finally {
        commit('shop/setLoadingProcess', false, { root: true })
        consoleMsgFinally('shop/fetchDataOrder', 'La petición para obtener los datos del guest ha terminado')
    }
}

export async function sendInfoClient(context, cliente) {

    const client = toJson(cliente)
    const { paymentmethod } = client //método de pago del cliente con desestructuración, para usar despues
    try {
        context.commit('shop/setLoadingProcess', true, { root: true })
        const response = await Vue.axios({
            method: 'POST',
            url: '/customers/',
            data: client
        })
        const clientData = response && response.data

        const orderPaid = await managePaymentFlow(context, {clientData, client})

        if (!orderPaid) throw new Error('Ha habido un error al realizar la orden, intentelo más tarde')

    } catch (error) {
        if (paymentmethod == 4) {
            context.commit('shop/setMakeRoomCharge', true, { root: true })
            context.commit('shop/setManualRoomChargeCliente', true, { root: true })
        }
        console.error(error)
    } finally {
        context.commit('shop/setLoadingProcess', false, { root: true })
        consoleMsgFinally('shop/sendInfoClient', 'La petición para enviar los datos del cliente se ha terminado')
    }
}

export async function managePaymentFlow(context, data) {
    try {
        const { commit, state, getters } = context
        const { clientData, client } = data
        const orderCreated = state.orderCreated || false
        let saleFinished = false


        const ownerBookingInCart = state.cart.items.find(items => items.categoryName == "ReservasOwners" )

        if(ownerBookingInCart && !ownerBookingInCart?.ownerBooking) {
            let ownerbooking = false

            const payloadBooking = createPayloadOwnerBooking({
                ...ownerBookingInCart,
                infoBeneficiaries: setGuestsInPayload(ownerBookingInCart?.guests),
                internalNotes: ownerBookingInCart?.note
            })

            ownerbooking = await saveOwnerBooking(context, payloadBooking) //guardo el nuevo booking

            if (!ownerbooking || ownerbooking?.length == 0) throw new Error('No ownerBooking created')
            else ownerBookingInCart.ownerBooking = ownerbooking
        }

        //armo el array details con los datos solicitados con los elementos del cart
        const totalItems= state.cart.items.filter( rate => rate.show != true || rate.typeRate === 'Pax' )  ;
        const detail = processItemsCart( totalItems, clientData)
        //objeto con los datos del client y detalles con los valores del cart (payload)
        const payload = createPayloadToSave(client, state, getters, detail, clientData)
        // console.log(payload)
        // return
        
        if (orderCreated) saleFinished = await retryPayment({ commit }, { order: orderCreated })
        else saleFinished = await sendCartItems({ commit }, payload)

        if (saleFinished?.status) {

            commit('shop/setEmptyCart', [], { root: true }) // [] cart se vacía, se resetean datos
            commit('shop/unsetLastNameSearch', '', { root: true })
            commit('shop/unsetDataRoom', '', { root: true })
            commit('bookings/resetToInitialState', null, { root: true })

            Vue.swal.fire('Operación finalizada', `🎉🎉 La orden ha sido completada: ${saleFinished?.invoice}. 🎉🎉`, 'success')

            if (payload.files){
                payload.files.idOrder = order
                await uploadFileFromOrder({commit}, files)
                commit('shop/deleteFiles', '', { root: true })
            }
        }

        return saleFinished

    } catch (error) {
        console.error(error)
    } finally {
        consoleMsgFinally('shop/managePaymentFlow', 'El flujo de pago ha terminado')
    }
}

//Obtener fechas no disponibles por  producto
export async function fetchBlackoutDatesProduct(context, datos) {
    const { idP, qty, fini  } = datos
    try {
        const response = await Vue.axios({
            url: '/allotment/notavailable/',
            params: { idP, qty, fini }
        })
        const payload = response && response.data
        return payload
    } catch (error) {
        console.log(error)
        return []
    } finally {
        consoleMsgFinally('fetchBlackoutDatesProduct', 'end')
    }
}

export async function retryPayment({ commit }, idOrder) {

    try {
        const response = await Vue.axios({
            method: 'POST',
            url: '/paymentretry/',
            data: idOrder
        })

        const respuesta = response && response.data
        const { payment, order, invoice } = respuesta

        if (order) { //si hay orden creada seteo el valor
            commit('shop/setOrderCreated', order, { root: true })
        }

        if (payment) {
            const { error, url } = payment
            //sino hay error true y url viene, se redirecciona a formulario de pago
            if (!error && url != '') {
                window.location = url
            }
        } else return {status: true, invoice}

    } catch (error) {
        console.log(error)
        return {status: false, invoice: null}
    } finally {
        consoleMsgFinally('shop/retryPayment', 'La petición para voler a realizar el pago finalizó')
    }
}

export async function sendCartItems({ commit }, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: '/orders/',
            data: payload
        })
 
        const respuesta = response && response.data
        const { payment, order, invoice, terminal } = respuesta
        if (order) { //si hay orden creada seteo el valor
            commit('shop/setOrderCreated', order, { root: true })
        }
        if (payment && !terminal) {
            const { error, url } = payment
            //sino hay error true y url viene, se redirecciona a formulario de pago
            if (!error && url != '') {
                window.location = url
            }
        } else return {status: true, invoice}

    } catch (error) {
        console.log(error)
        return {status: false, invoice: null}
    } finally {
        consoleMsgFinally('shop/sendCartItems', 'La petición para el envío de los detalles de la compra finalizó')
    }
}

export async function cancelOrder({ commit }, idOrder) {

    try {
        commit('shop/setLoadingProcess', true, { root: true })

        /*commit('setLoading', true, { root: true} ) //start loader
        const response = await Vue.axios({
            method:'POST',
            url:'/paymentretry/',
            data: idOrder
        })
        const respuesta = response && response.data  */

        commit('shop/setEmptyCart', [], { root: true })
        commit('shop/unsetLastNameSearch', '', { root: true })
        commit('shop/unsetDataRoom', '', { root: true })

        showAlertMessage('Cancelación', 'InfoIcon', `🎉🎉 Orden cancelada completada: ${idOrder || '' }. 🎉🎉`, 'success', 4000, 'bottom-right')
        // commit('shop/unsetErrorCancelOrderCart', '', { root: true })
    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        console.log(error)
        // commit('shop/setErrorCancelOrderCart', errorMsg, { root: true })
    } finally {
        commit('shop/setLoadingProcess', false, { root: true })
        consoleMsgFinally('shop/cancelOrder', 'La petición para cancelar la orden finalizó')
    }
}

export async function processTrueValues(context, items) {
    const response = await calculateTotalsManualy(context, payload)

    if (response){
        context.commit('shop/setTotalCurrencyBreakdown', response?.data, { root: true })
        return true
    } else throw Error('falló al calcular totales')

}

export async function fetchListDescriQuoted({ commit }, datos) {
    const { typeTitle, idLanguage } = datos
    try {
        const response = await Vue.axios({
            url: `/catalogs/descripcionCotizacion/`,
            params: {
                idLanguage,
                typeTitle
            }
        })
        const payload = response && response.data
        let newTerms = []
        payload.forEach((term) => {
            const { id, title } = term.data
            term.idWelcom = id
            term.title = title
            delete term.data
            newTerms.push(term)
        })
        commit('shop/setListDescriQuoted', payload, { root: true })

    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('shop/setListDescriQuoted', 'La petición ha finalizado')
    }
}

export async function fetchListDescriQuotedFired({ commit }, datos) {
    const { typeTitle, idLanguage } = datos
    try {
        const response = await Vue.axios({
            url: `/catalogs/descripcionCotizacion/`,
            params: {
                idLanguage,
                typeTitle
            }
        })
        const payload = response && response.data
        let newTerms = []
        payload.forEach((term) => {
            const { id, title } = term.data
            term.idFired = id
            term.title = title
            delete term.data
            newTerms.push(term)
        })


        commit('shop/setListDescriQuotedFired', payload, { root: true })


    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('shop/setListDescriQuotedFired', 'setListDescriQuotedFired finalizado')
    }
}

export async function fetchsetListDescriTerms({ commit }, datos) {
    const { typeTerms, idLanguage } = datos
    try {
        const response = await Vue.axios({
            url: `/catalogs/terminosycondiciones/`,
            params: {
                idLanguage,
                typeTerms
            }
        })
        const payload = response && response.data
        let newTerms = []
        payload.forEach((term) => {
            const { title } = term.data
            term.title = title
            delete term.data
            newTerms.push(term)
        })


        commit('shop/setListDescriTerms', payload, { root: true })


    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('shop/setListDescriTerms', 'setListDescriTerms finalizado')
    }
}

export async function fetchsetListDescriAmenities({ commit }, datos) {

    const { idHotel, idLanguage } = datos

    try {
        const response = await Vue.axios({
            url: `/catalogs/amenities/`,
            params: {
                idLanguage,
                idHotel
            }
        })
        const payload = response && response.data
        commit('shop/setListDescriAmenities', payload, { root: true })
    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('shop/setListDescriAmenities', 'setListDescriAmenities finalizado')
    }
}

export async function checkUserAuthorizeRate({ commit }, datos) {
    const { username, code, canalVenta } = datos
    try {
        const response = await Vue.axios({
            url: `/authlogin/`,
            params: {
                username,
                canalVenta
            }
        })
        const respuesta = response && response.data
        const { status } = respuesta
        if (status) {
            const { id } = respuesta
            const payload = { code, idUser: id, isInvalid: false, username }
            commit('shop/setDataUserAuthorization', payload, { root: true })
            showAlertMessage('Usuario encontrado', 'InfoIcon', '🎉🎉 El usuario sí es válido', 'success', 4000, 'bottom-right')

        } else {
            const payload = { code, idUser: null, isInvalid: true, username }
            commit('shop/setDataUserAuthorization', payload, { root: true })
            showAlertMessage('No existe usuario, datos incorrectos', 'InfoIcon', '😪😪 Usuario incorrecto, o el usuario no existe', 'danger', 4000, 'bottom-right')
        }
    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('shop/checkUserAuthorizeRate', 'La petición para verificar usuario si es valido finalizado')
    }
}

export async function authorizeRate({ commit }, datos) {
    const { username, file, user } = datos
    // console.log(datos, file )
    try {
        // commit('shop/setLoadingProcess', true, { root: true }) //init loader
        const formData = new FormData()
        formData.append('file', file)
        formData.append('data', JSON.stringify(user))
            // console.log(formData)
        const response = await Vue.axios({
            method: 'POST',
            url: `/authlogin/`,
            data: formData
        })
        const respuesta = response && response.data
        const { status, urlAuthorization } = respuesta
        if (status) {
            showAlertMessage(
                'Autorización realizada', 'InfoIcon',
                '🎉🎉La autorización ha sido exitoso',
                'success', 4000, 'bottom-right'
            )
            const dataUrl = { url: urlAuthorization }
            commit('shop/setUserAuthorizationUrl', dataUrl, { root: true })
        }
    } catch (error) {
        if (error.response) {
            if (error.response.statusText == 'Unauthorized') {
                showAlertMessage('Código incorrecto', 'InfoIcon', `😪😪 El código para su usuario ${username} es incorrecto`, 'danger', 4000, 'bottom-right')
                commit('shop/unsetDataUserAuthorization', datos, { root: true })
            }
            if (error.response.statusText == 'Bad Request') {
                showAlertMessage('Bad Request', 'InfoIcon', `😪😪 Petición invalida`, 'danger', 4000, 'bottom-right')
                commit('shop/unsetDataUserAuthorization', datos, { root: true })
            }
        }
    } finally {
        // commit('shop/setLoadingProcess', false, { root: true }) //init loader
        consoleMsgFinally('shop/authorizeRate', 'end')
    }
}

export async function fetchInclucions() {
    try {
        const response = await Vue.axios({
            url: '/catalogs/inclusions/'
        })
        const payload = response && response.data


        payload.forEach((incluciones) => {
            incluciones.value = incluciones.id,
            incluciones.text = incluciones.name

        })
        return payload
    } catch (error) {
        console.log( error )
    } finally {
        consoleMsgFinally('start/fetchInclucions', 'end')
    }
}

export async function sendEpayment({ commit }, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: '/payment/ingenicotoken/',
            data: payload
        })
        const respuesta = response && response.data
        const { email, nbresponse, reference, success, today } = respuesta
        const {action} = payload
        if (success === 'true') {
            commit('shop/deleteUserTokenCard', [], { root: true })
            if (action === 'createpayment') Vue.swal.fire('Congratulations!', `Your payment was completed successfully at ${today} with reference ${reference}.`, 'success')
            else if (action === 'updatecard') Vue.swal.fire('Success!', `Card info has been updated at ${today}`, 'success')
            return true
        } else {
            console.error(nbresponse)
            if (action === 'createpayment') Vue.swal.fire('The transaction has been declined', `Would you like to try again?.`, 'error')
            else if (action === 'updatecard') Vue.swal.fire('We were unable to process your request', `There was an error while performing the update. Check your card or try again later.`, 'error')
            return false
        }
    } catch (error) {
        console.log(error)
        Vue.swal.fire('Error', `We have problems processing your request, please try later.`, 'error')
        return false
    } finally {
        commit('shop/deleteUserTokenCard', [], { root: true })
        consoleMsgFinally('shop/sendEpayment', 'La petición para la actualización de la tarjeta del cliente finalizó')
    }
}

export async function calculateTotalWithDiscounts(context) {
    try {
        context.commit('validPromo', { root: true })
        const payload = makeTotalAndDiscountPayload()
        context.commit('shop/setIsReloadingProductsInCart', true, { root: true })

        const response = await Vue.axios({
            method: 'POST',
            url: '/process/Totals',
            data: {products: payload}
        })
        const respuesta = response && response.data
        if (respuesta?.status) {
            context.commit('shop/setTotalBreakdown', respuesta?.data, { root: true })
        }

        // context.commit('calculateTotalBreakdown', { root: true })
        return true
    } catch (error) {
        console.log(error)
        return false
    } finally {
        context.commit('shop/setIsReloadingProductsInCart', false, { root: true })
        consoleMsgFinally('shop/calculateTotalWithDiscounts', 'La petición para calcular los totales del carrito de compras se ha terminado')
    }

}

export async function calculateTotalsManualy(context, payload) {
    try {

        const response = await Vue.axios({
            method: 'POST',
            url: '/process/Totals',
            data: {products: payload}
        })
        const respuesta = response && response.data
        return respuesta?.data || false

        // context.commit('calculateTotalBreakdown', { root: true })
    } catch (error) {
        console.log(error)
        return false
    } finally {
        consoleMsgFinally('shop/calculateTotals', 'La petición para calcular los totales del carrito de compras se ha terminado')
    }

}


export async function sendValidatePOS({ commit }, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: '/payment/validatePOS/',
            data: payload
        })
        const respuesta = response && response.data
        const {result} = respuesta

        return result
    } catch (error) {
        console.log( error )
    } finally {
        consoleMsgFinally('start/sendValidatePOS', 'end')
    }
}

export async function calculateValuesWithCurrency(context, payload) {
    try {
        const {data, currency, toCurrency} = payload

        const response = await Vue.axios({
            method: 'POST',
            url: '/process/toCurrency',
            data: {
                data,
                currency,
                toCurrency
            }
        })
        const respuesta = response && response.data
        if (respuesta?.status) {
            return respuesta.data
        } else return false

    } catch (error) {
        console.log(error)
        return false
    } finally {
        consoleMsgFinally('shop/calculateTotalWithCurrency', 'La petición para calcular valores según divisa ha terminado')
    }

}

export async function benefitDiscountManager(context, payload) {
    try {
        context.commit('shop/setIsLoadingBenefitsDiscountsAssingnment', true, { root: true })
        const data = makeParamsDiscountManager(payload)

        const response = await Vue.axios({
            method: 'POST',
            url: '/fivesClub/benefitDiscountManager/',
            data: data
        })
        const respuesta = response && response.data

        if (respuesta.status) {
            context.commit('shop/assignBenefitsDiscounts', respuesta.data, { root: true })
            return true
        }
        else return false

    } catch (error) {
        console.log(error)
        context.commit('shop/assignBenefitsDiscounts', [], { root: true })
        return false
    } finally {
        context.commit('shop/setIsLoadingBenefitsDiscountsAssingnment', false, { root: true })
        consoleMsgFinally('shop/benefitDiscountManager', 'La petición para calcular el uso de beneficios por productos ha terminado')
    }

}